import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <g transform="translate(24.000000, 27.000000)">
          <path
            d="M0.136292 25.3C0.616292 32.5 5.17629 37.6 13.5163 37.6C21.4363 37.6 26.8363 33.04 26.8363 23.68V0.160004H19.1563V23.62C19.1563 28.36 17.3563 30.64 13.4563 30.64C10.1563 30.64 8.35629 28.66 8.05629 25.3H0.136292Z"
            fill="currentColor"
          />
        </g>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
